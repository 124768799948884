import React from 'react'
import {Link} from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css"
import {Alert} from 'react-bootstrap'
import {Config} from '../config.js'

function NotFound(){

  return (
    <div className="main">
      <div className="main_loading">
        <Alert variant="danger"><h1>Page not found!</h1></Alert>
        <br/><Link to={Config.appRoot}>Go to Main Page</Link>
      </div>
    </div>
  )
}

export default NotFound
