import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import {Accordion, Badge, Card, Tab, Tabs, Table} from 'react-bootstrap';
import Chart from "react-apexcharts";

function Detail(props) {
  let open = props.open

  function handleClick(id) {
    if (props.open){
      props.onSelectUniversity(null)
      open = false
    } else {
      props.onSelectUniversity(id)
    }
  }

  const options = {
    labels: ['Computer Science', 'Cryptography', 'Humanistic', 'Mathematics', 'Privacy', "Security"],
    dataLabels: {
      enabled: false
    },
    colors:['#c10001', '#ff8f00', '#00b22c', '#1a9391', '#4643bb', '#d223fe'],
    legend: {
      show: true,
      position: 'bottom'
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value + " %";
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        }
      }
    }]
  }

	return (
		<div className="list_item" id={props.university.id}>
			<div className="list_item_header" onClick={() => handleClick(props.university.id)}>
		    <h6>{props.university.name}</h6>
        <div className="addInfo">{props.university.country}</div>
      </div>

			<Collapse in={open} className="list_item_collapse">
				<div>

          <Accordion >

            {props.university.programs.map((program, index) => {
              let subjects_percentage = [
                parseFloat(program.percentage_of_subjects_on.computer_science),
                parseFloat(program.percentage_of_subjects_on.cryptography),
                parseFloat(program.percentage_of_subjects_on.humanistic),
                parseFloat(program.percentage_of_subjects_on.mathematics),
                parseFloat(program.percentage_of_subjects_on.privacy),
                parseFloat(program.percentage_of_subjects_on.security)
              ]

              if (!program.degree || 0 === program.degree.length) program.degree = "NA"
              if (!program.degree_title || 0 === program.degree_title.length) program.degree_title = "NA"
              if (!program.duration || 0 === program.duration.length) program.duration = "NA"
              if (!program.cost || 0 === program.cost.length) program.cost = "NA"
              if (!program.practical_lectures || 0 === program.practical_lectures.length) program.practical_lectures = "NA"
              if (!Array.isArray(program.language) || program.language.length === 0) program.language.push("NA")
              if (!props.university.world_university_rankings || 0 === props.university.world_university_rankings.length) props.university.world_university_rankings = "NA"

              return(

                <Card key={index}>

                  <Accordion.Toggle as={Card.Header} eventKey={index} className="detail_accordion_header">

                  {/*
                    <Container>
                      <Row className="list_item_badge">
                        <Col xs={3}>
                          <Badge  variant="secondary">{
                            program.degree.includes("Master") ? 'Master' : (
                              program.degree.includes("Bachelor") ? 'Bachelor' : (
                                program.degree.includes("Doctoral") ? 'PhD' : (
                                  program.degree.includes("Post-graduate") ? <>Post-<br/>graduate</> : program.degree
                                )
                              )
                            )}</Badge>
                        </Col>
                        <Col xs={9}>
                          {program.study_program}
                        </Col>
                      </Row>
                    </Container>
                    */}

                    <div>
                      <Badge  variant="secondary">{
                        program.degree.includes("Master") ? 'Master' : (
                          program.degree.includes("Bachelor") ? 'Bachelor' : (
                            program.degree.includes("Doctoral") ? 'PhD' : (
                              program.degree.includes("Post-graduate") ? <>Post-<br/>graduate</> : program.degree
                            )
                          )
                        )
                      }</Badge>
                    </div>
                    <div>
                      {program.study_program}
                    </div>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={index}>
                     <Card.Body>

                      <Tabs defaultActiveKey="main" className="detail_tab">

                        <Tab eventKey="main" title="Main">
                          <Table responsive size="sm" className="detail_table main_info">
                            <tbody>
                              <tr>
                                <td>Study Program:</td>
                                <td>{program.study_program}</td>
                              </tr>
                              <tr>
                                <td>World University Rankings:</td>
                                <td>{props.university.world_university_rankings}</td>
                              </tr>
                              <tr>
                                <td>Degree:</td>
                                <td>{program.degree}</td>
                              </tr>
                              {/*
                              <tr>
                                <td>Degree Title:</td>
                                <td>{program.degree_title}</td>
                              </tr>
                              */}
                              <tr>
                                <td>Language:</td>
                                <td>{program.language.map((item, index) => {
                                  if (index === program.language.length -1)
                                    return item
                                  else
                                    return item + ", "
                                })}</td>
                              </tr>
                              <tr>
                                <td>Duration:</td>
                                <td>{program.duration}</td>
                              </tr>
                              <tr>
                                <td>Cost per year:</td>
                                <td>{program.cost + " €"}</td>
                              </tr>
                              <tr>
                                <td>Practical lectures min.:</td>
                                <td>{program.practical_lectures.toString().includes("%") ? program.practical_lectures : program.practical_lectures + " %"}</td>
                              </tr>
                              {program.link ?
                                <tr>
                                  <td>Link:</td>
                                  <td className="university_link"><div ><a href={program.link}  target="_blank" rel="noopener noreferrer">{program.link}</a></div></td>
                                </tr> : null
                              }
                            </tbody>
                          </Table>
                          <div className="detail_note">{program.note}</div>
                        </Tab>

                        <Tab eventKey="topics" title="Compulsory subjects with main focus on">
                          {
                            subjects_percentage.reduce((total, num) =>{
                              return total + num
                            }) > 0 ?
                            <Chart options={options} type="pie" id={props.university.id + '-' + index}series={subjects_percentage} />
                            :
                            <div className="subjects_chart_no_data">
                              No data
                            </div>
                          }
{/*
                          <Table responsive size="sm" className="detail_table topics">
                            <tbody>
                              <tr>
                                <td>Computer Science:</td>
                                <td>{program.percentage_of_subjects_on.computer_science}</td>
                              </tr>
                              <tr>
                                <td>Cryptography:</td>
                                <td>{program.percentage_of_subjects_on.cryptography}</td>
                              </tr>
                              <tr>
                                <td>Humanistic:</td>
                                <td>{program.percentage_of_subjects_on.humanistic}</td>
                              </tr>
                              <tr>
                                <td>Mathematics:</td>
                                <td>{program.percentage_of_subjects_on.mathematics}</td>
                              </tr>
                              <tr>
                                <td>Privacy:</td>
                                <td>{program.percentage_of_subjects_on.privacy}</td>
                              </tr>
                              <tr>
                                <td>Security:</td>
                                <td>{program.percentage_of_subjects_on.security}</td>
                              </tr>
                            </tbody>
                          </Table>
*/}

                        </Tab>

                      </Tabs>
                    </Card.Body>
                  </Accordion.Collapse>

                </Card>
              )
            })}

          </Accordion>
        </div>

			</Collapse>
    </div>
	)

}

export default Detail
