import React from 'react'
import Detail from './Detail.js'
import {Col,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {Config} from '../config.js'

class ListOfDetails extends React.Component {

  handleClick = (id) => {
    this.props.onSelectUniversity(id)
  }

  loadData = () => {
    /*
    let content = null
    if (Array.isArray(this.props.data) && this.props.data.length){
      content = [].concat(this.props.data)
      .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      .map((university, index) => {
        let open = false
        if  (this.props.focus === university.id ) open = true
        return (
          <Detail key={index} university={university} onSelectUniversity={this.handleClick} focus={this.props.focus} open={open}/>
        )
      })
    } else {
      content = <div className="empty-data"><Spinner animation="grow" /><h6>No university matches the selected filter!</h6></div>
    }
    return content

    */

    let content = null
    if (Array.isArray(this.props.data) && this.props.data.length){
      content = [].concat(this.props.data)

      .map((university, index) => {
        let open = false
        if  (this.props.focus === university.id ) open = true
        return (
          <Detail key={index} university={university} onSelectUniversity={this.handleClick} focus={this.props.focus} open={open}/>
        )
      })
    } else {
      content = <div className="empty-data"><Spinner animation="grow" /><h6>No university matches the selected filter!</h6></div>
    }
    return content
  }

  render() {
    if (Number.isInteger(this.props.focus)) {
      let focusElement = [...document.getElementsByClassName("list_item")].filter(el => parseInt(el.id) === parseInt(this.props.focus))
      if (focusElement.length === 1) focusElement[0].scrollIntoView({block: 'center', behavior: 'smooth'})
    }

    return (
      <Col xs={3} className="detail-column">
        <Link to={Config.appRoot + "add-university"} >Add your university</Link>
        {this.loadData()}
      </Col>
    )
  }
}

export default ListOfDetails
