import React from 'react'
import Axios from 'axios'
import Map from './Map.js'
import Filter from './Filter.js'
import ListOfDetails from './ListOfDetails.js'
import {Config} from '../config.js'
import "bootstrap/dist/css/bootstrap.min.css"
import {Alert, Container, Row, Spinner} from 'react-bootstrap'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      data: null,
      dataToShow: null,
      focus: null,
      error: null
    }
  }

  componentDidMount() {
    /* ČERPÁ DATA PŘES HTTP GET REQUEST */
    this.fetchData()
  }

  fetchData = () => {
    const encoded = new Buffer('gimmeData').toString('base64')
    Axios.get(Config.server.getData + '?p='+encoded)
      .then((response) => {
        if (typeof(response.data) !== "undefined" && response.data !== null && response.data.length !== 0){
          let sortedUniv = response.data.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))
          let sortedUnivProg = sortedUniv.map(univ => {
              univ.programs = univ.programs.sort((a, b) => (a.degree > b.degree) ? 1 : (a.degree === b.degree) ? ((a.study_program > b.study_program) ? 1 : -1) : -1 )
              return univ
          })
          this.setState({
            isLoading: false,
            data: sortedUnivProg,
            dataToShow: sortedUnivProg,
          })
        } else {
          this.setState({error:{message: "There is no university in the database!"}})
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({error})
      })
  }

  handleDetailClick = (value) => {
    this.setState({
      focus: value
    })
  }

  handleMapClick = (value) => {
    this.setState({
      focus: value
    })
  }

  handleFilterChange = (newData) => {
    let newCount = 0, count = 0
    newData.forEach((university) => {
      newCount += university.programs.length
    })
    this.state.dataToShow.forEach((university) => {
      count += university.programs.length
    })
    if (count !== newCount) this.setState({dataToShow: newData})
  }

  render(){
    const { isLoading, data, dataToShow, focus, error } = this.state

    var markers = dataToShow ? (
      dataToShow.map((item) => {
        return ({id: item.id, cor: item.cor, name: item.name, country: item.country})
      })) : null

    return (


      <div className="main">

        {error ? <Alert variant="danger">{error.message}</Alert> : null}

        {
          !isLoading ? (
            <Container fluid >
              <Row>
                  <Filter data={data} toShow={this.handleFilterChange}/>
                  <ListOfDetails data={dataToShow} focus={focus} onSelectUniversity={this.handleDetailClick}/>
                  <Map markers={markers} focus={focus} onSelectMarker={this.handleMapClick}/>
              </Row>
            </Container>
          ) :  error ? ( <div className="main_loading">Sorry, something went wrong :-(</div>
          ) : (
            <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading</div>
          )
        }

      </div>

    )
  }
}

export default App;
