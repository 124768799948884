import React from 'react'
import Mammoth from 'mammoth'
import Axios from 'axios'
import {Alert, Badge, Button, Col, Form, Row, Spinner} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import {Config} from '../config.js'


class AdminImport extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      data: null,
      process: null,
      newData: null,
      validated: false
    }
  }

  emptyProgram = () => {
    return ({
      study_program : "",
      department : "",
      degree : "",
      degree_title : "",
      language : [""],
      duration : "",
      cost : "",
      link : "",
      note: "",
      practical_lectures: "",
      percentage_of_subjects_on: {
        computer_science: "",
        cryptography: "",
        humanistic: "",
        mathematics: "",
        privacy: "",
        security: ""
      }
    })
  }

  componentDidMount() {
    /* ČERPÁ DATA PŘES HTTP REQUEST */
    this.fetchData()
  }

  fetchData = () => {
    const encoded = new Buffer('gimmeData').toString('base64')
    Axios.get(Config.server.getData + '?p='+encoded)
      .then((response) => {
        if (typeof(response.data) !== "undefined" && response.data !== null){
          let sortedUniv = response.data.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))
          let sortedUnivProg = sortedUniv.map(univ => {
              univ.programs = univ.programs.sort((a, b) => (a.degree > b.degree) ? 1 : (a.degree === b.degree) ? ((a.study_program > b.study_program) ? 1 : -1) : -1 )
              return univ
          })
          this.setState({
            isLoading: false,
            data: sortedUnivProg
          })
        } else {
          this.setState({process: {type: "danger", message: "The database is empty."}})
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({process: {type: "danger", message: "Unable to load data."}})
      })
  }

  parseWordDocxFile = (inputElement) => {
    var files = inputElement.target.files || []
    if (!files.length) return;

    [...files].forEach(file => {
      let self = this
      var reader = new FileReader();
      reader.onloadend = function(event) {
        var arrayBuffer = reader.result;
        Mammoth.convertToHtml({arrayBuffer: arrayBuffer}).then((resultObject) => {
          self.prepareData(resultObject.value)
        })
      }
      reader.readAsArrayBuffer(file)
    })
  }

  prepareData = (inputDom) => {
    try {
      var doc = new DOMParser().parseFromString(inputDom.split('>NA<').join('><'), 'text/html').body;
      var tdNodes = doc.querySelectorAll('td')

      var name = [...tdNodes].filter(a => a.textContent.trim() === "University Name").map(a => a.nextSibling.textContent.trim())
      var country = [...tdNodes].filter(a => a.textContent.trim() === "Country").map(a => a.nextSibling.textContent.trim())
      var cor = [...tdNodes].filter(a => a.textContent.trim() === "GPS").map(a => a.nextSibling.textContent.trim())
      var study_programs = [...tdNodes].filter(a => a.textContent.trim() === "Study Program").map(a => a.nextSibling.textContent.trim())
      var departments = [...tdNodes].filter(a => a.textContent.trim() === "Department").map(a => a.nextSibling.textContent.trim())
      var degrees = [...tdNodes].filter(a => a.textContent.trim() === "Degree0").map(a => a.nextSibling.textContent.trim())
      var degree_titles = [...tdNodes].filter(a => a.textContent.trim() === "Degree Title").map(a => a.nextSibling.textContent.trim())
      var languages = [...tdNodes].filter(a => a.textContent.trim() === "Language").map(a => a.nextSibling.textContent.trim())
      var durations = [...tdNodes].filter(a => a.textContent.trim() === "Duration").map(a => a.nextSibling.textContent.trim())
      var costs = [...tdNodes].filter(a => a.textContent.trim() === "Cost0").map(a => a.nextSibling.textContent.trim())
      var practical_lectures = [...tdNodes].filter(a => a.textContent.trim() === "Practical lectures0,4").map(a => a.nextSibling.textContent.trim())
      var links = [...tdNodes].filter(a => a.textContent.trim() === "Link").map(a => a.nextSibling.textContent.trim())
      var ranking = [...tdNodes].filter(a => a.textContent.trim() === "World University Rankings 20191").map(a => a.nextSibling.textContent.trim())

      var mandatory_subjects = [...tdNodes].filter(a => a.textContent.trim() === "Percentage of mandatory subjects on3").map(a => a.parentNode.nextSibling)

      var computer_sciences = [], cryptographys = [], humanistics = [], mathematics = [], privacies = [], securities = []
      mandatory_subjects.forEach((program, index) => {
        var subjectsPNodes = program.querySelectorAll("p")
        try {computer_sciences.push([...subjectsPNodes].filter(a => a.textContent.trim() === "Computer Sc.:").map(a => a.nextSibling.textContent.trim())[0])} catch(err) {computer_sciences.push("")}
        try {cryptographys.push([...subjectsPNodes].filter(a => a.textContent.trim() === "Crypto:").map(a => a.nextSibling.textContent.trim())[0])} catch(err) {cryptographys.push("")}
        try {humanistics.push([...subjectsPNodes].filter(a => a.textContent.trim() === "Humanistic:").map(a => a.nextSibling.textContent.trim())[0])} catch(err) {humanistics.push("")}
        try {mathematics.push([...subjectsPNodes].filter(a => a.textContent.trim() === "Math:").map(a => a.nextSibling.textContent.trim())[0])} catch(err) {mathematics.push("")}
        try {privacies.push([...subjectsPNodes].filter(a => a.textContent.trim() === "Privacy:").map(a => a.nextSibling.textContent.trim())[0])} catch(err) {privacies.push("")}
        try {securities.push([...subjectsPNodes].filter(a => a.textContent.trim() === "Security:").map(a => a.nextSibling.textContent.trim())[0])} catch(err) {securities.push("")}
      })
    } catch(err){
      console.log(err)
      this.setState({process: {type: "danger", message: "An error occured reading data from the file."}})
      return
    }

    if (name.length<1 || cor.length<1 || country.length<1 || study_programs.length<1 || departments.length<1 || degrees.length<1 || degree_titles.length<1 || languages.length<1 || durations.length<1 || costs.length<1 || practical_lectures.length<1 || links.length<1 || ranking.length<1 || computer_sciences.length<1 || cryptographys.length<1 || humanistics.length<1 || mathematics.length<1 || privacies.length<1 || securities.length<1) {
      this.setState({process: {type: "danger", message: "Unsupported document structure: some required data is missing."}})
      return
    }

    let newItems = {
      name: name[0],
      country : country[0],
      cor : {
        lat : isNaN(parseFloat(cor[0].split(",")[0])) ? '' : parseFloat(cor[0].split(",")[0]),
        lng : isNaN(parseFloat(cor[0].split(",")[1])) ? '' : parseFloat(cor[0].split(",")[1])
      },
      world_university_rankings : ranking[0],
      programs: [],
      created: {
        username: "",
        usermail: "",
        userphone: "",
        timestamp: ""
      },
    }

    departments.forEach((item, index) => {
      let d = new Date()
      let newItem = {
        study_program : study_programs[index],
        department : departments[index],
    		degree :
          degrees[index].toLowerCase().includes("bachelor") ? "Bachelor" :
          degrees[index].toLowerCase().includes("master") ? "Master" :
          degrees[index].toLowerCase().includes("doctor") ? "Doctoral" :
          degrees[index].toLowerCase().includes("post") ? "Post-graduate" : "",
        degree_title : degree_titles[index],
        language : languages[index].split(/,|\//).map(language => language.trim()),
        //split(/\s*[,]\s*/)
        //split(,|\/)
    		duration :
          durations[index].toLowerCase().includes("1 year") ? "1 year" :
          durations[index].toLowerCase().includes("2 year") ? "2 years" :
          durations[index].toLowerCase().includes("3 year") ? "3 years" :
          durations[index].toLowerCase().includes("4 year") ? "4 years" :
          durations[index].toLowerCase().includes("other") ? "other" : "",
    		cost : isNaN(parseFloat(costs[index])) ? '' : parseFloat(costs[index]),
        link : links[index],
        note: "Data collected in academic year " + (d.getFullYear()-1) + "-" + d.getFullYear(),
    		practical_lectures: isNaN(parseFloat(practical_lectures[index])) ? '' : parseFloat(practical_lectures[index]),
    		percentage_of_subjects_on: {
    			computer_science: isNaN(parseFloat(computer_sciences[index])) ? '' : parseFloat(computer_sciences[index]),
    			cryptography: isNaN(parseFloat(cryptographys[index])) ? '' : parseFloat(cryptographys[index]),
    			humanistic: isNaN(parseFloat(humanistics[index])) ? '' : parseFloat(humanistics[index]),
    			mathematics: isNaN(parseFloat(mathematics[index])) ? '' : parseFloat(mathematics[index]),
    			privacy: isNaN(parseFloat(privacies[index])) ? '' : parseFloat(privacies[index]),
    			security: isNaN(parseFloat(securities[index])) ? '' : parseFloat(securities[index]),
    		}
      }
      newItems.programs.push(newItem)
    })

    if (this.state.data.some(university => university.name === newItems.name.trim())) {
      this.setState({newData: newItems, process: {type: "danger", message: "Unable to process. There is another university with the same name. Edit existing university or delele university and try to upload again."}})
    } else {
      this.setState({newData: newItems})
    }
  }

  saveNewData = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!this.state.newData){
      this.setState({process: {type: "danger", message: "No data to upload!"}})
      return
    }

    this.setState({validated: true})
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    this.setState({isLoading: true, validated: false})

    let dataToSend = this.state.newData
    let date = new Date()
    dataToSend.created.timestamp = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    dataToSend.created.username = "admin"

    Axios({
      method: 'post',
      url: Config.server.saveData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: {
        'universities': JSON.stringify(this.state.newData),
        'action': 'import',
        'fileName': ''
      }
    })
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.setState({
            process: {type: "success", message: "New study programs successfully uploaded!"},
            newData: null
          })
          this.fetchData()
        } else {
          this.setState({
            process: {type: "danger", message: "Can not upload the data!"},
          })
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        this.setState({process: {type: "danger", message: "Can not upload the data!"}})
        console.log(error)
      })
  }

  cancelUpload = () => {
    this.setState({newData: null, process: null, validated: false})
  }

  addEmptyProgram = () => {
    let newItem = JSON.parse(JSON.stringify(this.state.newData))
    newItem.programs.push(this.emptyProgram())
    this.setState({newData: newItem})
  }

  deleteProgram = (id) => {
    let newItem = JSON.parse(JSON.stringify(this.state.newData))
    newItem.programs.splice(id, 1)
    this.setState({newData: newItem})
  }

  deleteLang = (idP, idL) => {
    let tmp = JSON.parse(JSON.stringify(this.state.newData))
    tmp.programs[idP].language.splice(idL,1)
    this.setState({newData: tmp})
  }


  render(){
    const { isLoading, newData, process, validated } = this.state

    return (

      <div className={!process && newData && !isLoading? 'admin_section scrollable' : 'admin_section'}>
        <div className="admin_header">
          <h3>Import new university</h3>
        </div>

        {process ?
          <div >
            <Alert variant={process.type}>{process.message}</Alert>
            <Button variant="outline-secondary" onClick={this.cancelUpload}>Go back</Button>
          </div>
          : (

            isLoading ? (
              <div className="admin_process_message"><Spinner animation="border" variant="primary" /><br/>Uploading</div>
            ) : (

              !newData ? (
                <div className="upload_form">
                  <div className="upload_program">
                    <Form>
                      <Form.Group as={Row} controlId="formFile">
                        <Form.Label column sm={6}>
                          Data file (only <b>.docx</b>)
                        </Form.Label>
                        <Col sm={6} className="col_formFile">
                          <Form.Control type="file" onChange={this.parseWordDocxFile} accept=".docx"/>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </div>

              ) : (

                <div className="upload_form">

                  <Form noValidate validated={validated} onSubmit={this.saveNewData}>
                    <div className="upload_program">
                      <h4>University details</h4>

                      <Form.Group as={Row} controlId="name">
                        <Form.Label column sm={4}>University name *</Form.Label>
                        <Col sm={8}>
                          <Form.Control required value={newData.name} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.name = e.target.value
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid university name.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="country">
                        <Form.Label column sm={4}>Country *</Form.Label>
                        <Col sm={8}>
                          <Form.Control required value={newData.country} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.country = e.target.value
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid country.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={4}>GPS *</Form.Label>
                        <Col sm={4}>
                          <Form.Control required type="number" id="cor-lat" placeholder="Latitude" value={newData.cor.lat} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.cor.lat = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid coordinates.
                          </Form.Control.Feedback>
                        </Col>
                        <Col sm={4}>
                          <Form.Control required type="number" id="cor-lng" placeholder="Longitude" value={newData.cor.lng} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.cor.lng = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid coordinates.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="world_university_rankings">
                        <Form.Label column sm={4}>World University Rankings</Form.Label>
                        <Col sm={8}>
                          <Form.Control value={newData.world_university_rankings} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.world_university_rankings = e.target.value
                            this.setState({newData: newItem})
                          }}/>
                        </Col>
                      </Form.Group>

                    </div>
                    {newData.programs.map((program, index) => {
                      const percent_checksum = Math.round((Object.keys(program.percentage_of_subjects_on).reduce((sum,key)=>sum+parseFloat(program.percentage_of_subjects_on[key]||0),0)) * 100) / 100
                      return (

                      <div className="upload_program" key={index}>
                        <Button variant="outline-danger" className="delete_program_button" size="sm" onClick={()=> this.deleteProgram(index)}
                        disabled={newData.programs.length === 1 ? true : false}>- Delete program</Button>
                        <h4>Study Program {index+1}</h4>

                        <Form.Group as={Row} controlId={"study_program-" + (index+1)}>
                          <Form.Label column sm={4}>Study program *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required value={program.study_program} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].study_program = e.target.value
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid name of the study program.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"department-" + (index+1)}>
                          <Form.Label column sm={4}>Department</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={program.department} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].department = e.target.value
                              this.setState({newData: newItem})
                            }}/>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"degree-" + (index+1)}>
                          <Form.Label column sm={4}>Degree *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required as="select" value={program.degree} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].degree = e.target.value
                              this.setState({newData: newItem})
                            }}>
                              <option></option>
                              <option>Bachelor</option>
                              <option>Master</option>
                              <option>Doctoral</option>
                              <option>Post-graduate</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid degree.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"degree_title-" + (index+1)}>
                          <Form.Label column sm={4}>Degree title</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={program.degree_title} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].degree_title = e.target.value
                              this.setState({newData: newItem})
                            }}/>
                          </Col>
                        </Form.Group>

                        {newData.programs[index].language.map((item, indx) => {
                          return (
                            <Form.Group as={Row} key={indx}>
                              <Form.Label column sm={4} >{indx === 0 ? "Language *" : ""}</Form.Label>
                              <Col sm={5}>
                                <Form.Control required id={"Language-"+(index+1)+"-"+(indx+1)} key={indx} value={item} onChange={(e) => {
                                  let newItem = JSON.parse(JSON.stringify(newData))
                                  newItem.programs[index].language[indx] = e.target.value
                                  this.setState({newData: newItem})
                                }}/>
                              </Col>
                              <Col sm={3} className="language-row-button">
                                <Button variant="outline-danger" size="sm" disabled={program.language.length === 1} onClick={() => this.deleteLang(index,indx)}>Delete language</Button>
                              </Col>
                            </Form.Group>
                          )
                        })}
                        <Form.Group as={Row}>
                          <Form.Label column sm={4} >{program.language.length === 0 ? "Language" : ""}</Form.Label>
                          <Col sm={5}>
                          </Col>
                          <Col sm={3} className="language-row-button">
                            <Button variant="outline-success" size="sm" onClick={() => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].language.push("")
                              this.setState({newData: newItem})
                            }}>Add language</Button>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"duration-" + (index+1)}>
                          <Form.Label column sm={4}>Duration *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required as="select" value={program.duration} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].duration = e.target.value
                              this.setState({newData: newItem})
                            }}>
                              <option></option>
                              <option>1 year</option>
                              <option>2 years</option>
                              <option>3 years</option>
                              <option>4 years</option>
                              <option>other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid duration.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"cost-" + (index+1)}>
                          <Form.Label column sm={4}>Cost per year</Form.Label>
                          <Col sm={4}>
                            <Form.Control type="number" value={program.cost} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].cost = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                          </Col>
                          <Form.Label column sm={4}>€</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"practical_lectures-" + (index+1)}>
                          <Form.Label column sm={4}>Practical lectures</Form.Label>
                          <Col sm={4}>
                            <Form.Control type="number" min="0" max="100" step=".01" value={program.practical_lectures} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].practical_lectures = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"link-" + (index+1)}>
                          <Form.Label column sm={4}>Link</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={program.link} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].link = e.target.value
                              this.setState({newData: newItem})
                            }}/>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"note-" + (index+1)}>
                          <Form.Label column sm={4}>Note</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={program.note} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].note = e.target.value
                              this.setState({newData: newItem})
                            }}/>
                          </Col>
                        </Form.Group>

                        <h5>Percentage of subjects on</h5>

                        <Form.Group as={Row} controlId={"computer_science-" + (index+1)}>
                          <Form.Label column sm={4}>Computer Science *</Form.Label>
                          <Col sm={4}>
                            <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.computer_science} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].percentage_of_subjects_on.computer_science = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid subject percentage.
                            </Form.Control.Feedback>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"cryptography-" + (index+1)}>
                          <Form.Label column sm={4}>Cryptography *</Form.Label>
                          <Col sm={4}>
                            <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.cryptography} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].percentage_of_subjects_on.cryptography = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid subject percentage.
                            </Form.Control.Feedback>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"humanistic-" + (index+1)}>
                          <Form.Label column sm={4}>Humanistic *</Form.Label>
                          <Col sm={4}>
                            <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.humanistic} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].percentage_of_subjects_on.humanistic = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid subject percentage.
                            </Form.Control.Feedback>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"mathematics-" + (index+1)}>
                          <Form.Label column sm={4}>Mathematics *</Form.Label>
                          <Col sm={4}>
                            <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.mathematics} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].percentage_of_subjects_on.mathematics = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid subject percentage.
                            </Form.Control.Feedback>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"privacy-" + (index+1)}>
                          <Form.Label column sm={4}>Privacy *</Form.Label>
                          <Col sm={4}>
                            <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.privacy} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].percentage_of_subjects_on.privacy = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid subject percentage.
                            </Form.Control.Feedback>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"security-" + (index+1)}>
                          <Form.Label column sm={4}>Security *</Form.Label>
                          <Col sm={4}>
                            <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.security} onChange={(e) => {
                              let newItem = JSON.parse(JSON.stringify(newData))
                              newItem.programs[index].percentage_of_subjects_on.security = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                              this.setState({newData: newItem})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid subject percentage.
                            </Form.Control.Feedback>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"checksum-" + (index+1)}>
                          <Col sm={4}></Col>
                          <Col sm={8}><Form.Text className="text-muted">Percent checksum: <Badge variant={percent_checksum === 100 ? 'success':'danger'}>{percent_checksum} %</Badge></Form.Text></Col>
                        </Form.Group>

                      </div>

                    )})}

                    <Button variant="outline-info" className="program_buttons" size="sm" onClick={this.addEmptyProgram}>+ Add next program</Button>

                    <div className="upload_buttons">
                      <Button variant="danger" className="upload_button" onClick={this.cancelUpload}>Cancel</Button>
                      <Button variant="success" className="upload_button" type="submit" >Confirm</Button>
                    </div>

                  </Form>
                </div>

              )
            )
          )
        }


      </div>
    )
  }
}

export default AdminImport;
