import React from 'react'
import Axios from 'axios'
import {Alert, Badge, Button, Col, Form, Row, Table, Spinner} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import {Config} from '../config.js'
import ReactExport from "react-data-export"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class AdminOverview extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      data: null,
      newData: null,
      process: null,
      validated: false,
      oldFileName: ""
    }
  }

  emptyProgram = () => {
    return ({
      study_program : "",
      department : "",
      degree : "",
      degree_title : "",
      language : [""],
      duration : "",
      cost : "",
      link : "",
      note: "",
      practical_lectures: "",
      percentage_of_subjects_on: {
        computer_science: "",
        cryptography: "",
        humanistic: "",
        mathematics: "",
        privacy: "",
        security: ""
      }
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const encoded = new Buffer('gimmeFullData').toString('base64')
    Axios.get(Config.server.getData + '?p='+encoded)
      .then((response) => {
        if (typeof(response.data) !== "undefined" && response.data !== null){
          let sortedUniv = response.data.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))
          let sortedUnivProg = sortedUniv.map(univ => {
              univ.programs = univ.programs.sort((a, b) => (a.degree > b.degree) ? 1 : (a.degree === b.degree) ? ((a.study_program > b.study_program) ? 1 : -1) : -1 )
              return univ
          })
          this.setState({
            data: sortedUnivProg,
            isLoading: false
          })
        } else {
          this.setState({process: {type: "danger", message: "The database is empty."}})
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({process: {type: "danger", message: "Unable to load data."}})
      })
  }

  saveUniversity = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({validated: true})

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    this.setState({isLoading: true, validated: false})

    Axios({
      method: 'post',
      url: Config.server.editData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: {
        'universities': JSON.stringify(this.state.newData),
        'action': 'overwrite',
        'fileName': this.state.oldFileName
      }
    })
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.setState({isLoading: false, process: {type: "success", message: "New study programs updated successfully."}})
        } else {
          this.setState({isLoading: false, process: {type: "danger", message: responseData.message}})
        }
        this.fetchData()
      })
      .catch((error) => {
        this.setState({isLoading: false, process: {type: "danger", message: "Unable to connect to database."}})
        console.log(error)
      })
  }

  deleteUniversity = (university) => {
    this.setState({isLoading: true})
    const data = {
      name: university.name,
      action: "univ"
    }
    const encoded = new Buffer(JSON.stringify(data)).toString('base64')

    Axios.get(Config.server.deleteData + '?p='+encoded)
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.setState({isLoading: false, process: null})
        } else {
          this.setState({isLoading: false, process: {type: "danger", message: responseData.message}})
        }
        this.fetchData()
      })
      .catch((error) => {
        this.setState({isLoading: false, process: {type: "danger", message: "Unable to connect to database."}})
        console.log(error)
      })
  }

  addEmptyProgram = () => {
    let newItem = JSON.parse(JSON.stringify(this.state.newData))
    newItem.programs.push(this.emptyProgram())
    this.setState({newData: newItem})
  }

  deleteProgram = (id) => {
    let newItem = JSON.parse(JSON.stringify(this.state.newData))
    newItem.programs.splice(id, 1)
    this.setState({newData: newItem})
  }

  deleteLang = (idP, idL) => {
    let tmp = JSON.parse(JSON.stringify(this.state.newData))
    tmp.programs[idP].language.splice(idL,1)
    this.setState({newData: tmp})
  }

  editUniversity = (university) => {
    let newUniversity = JSON.parse(JSON.stringify(university))
    newUniversity.programs.forEach((program, index) => {
        program.practical_lectures = isNaN(parseFloat(program.practical_lectures)) ? '' : parseFloat(program.practical_lectures)
        program.cost = isNaN(parseFloat(program.cost)) ? '' : parseFloat(program.cost)
        program.percentage_of_subjects_on.computer_science = isNaN(parseFloat(program.percentage_of_subjects_on.computer_science)) ? '' : parseFloat(program.percentage_of_subjects_on.computer_science)
        program.percentage_of_subjects_on.cryptography = isNaN(parseFloat(program.percentage_of_subjects_on.cryptography)) ? '' : parseFloat(program.percentage_of_subjects_on.cryptography)
        program.percentage_of_subjects_on.humanistic = isNaN(parseFloat(program.percentage_of_subjects_on.humanistic)) ? '' : parseFloat(program.percentage_of_subjects_on.humanistic)
        program.percentage_of_subjects_on.mathematics = isNaN(parseFloat(program.percentage_of_subjects_on.mathematics)) ? '' : parseFloat(program.percentage_of_subjects_on.mathematics)
        program.percentage_of_subjects_on.privacy = isNaN(parseFloat(program.percentage_of_subjects_on.privacy)) ? '' : parseFloat(program.percentage_of_subjects_on.privacy)
        program.percentage_of_subjects_on.security = isNaN(parseFloat(program.percentage_of_subjects_on.security)) ? '' : parseFloat(program.percentage_of_subjects_on.security)
    })

    this.setState({newData: newUniversity, oldFileName: university.name})
  }

  cancelUpload = () => {
    this.setState({newData: null, process: null, oldFileName: "", validated: false})
  }


  render(){
    const { data, isLoading, newData, process, validated } = this.state

    const downloadProgram = (university, programId) => {
      const dataSet = [
        {property: "University:", value: university.name},
        {property: "Country:", value: university.country},
        {property: "GPS:", value: university.cor.lat + "," + university.cor.lng},
        {property: "World university rankings:", value: university.world_university_rankings},
        {property: "Study program:", value: university.programs[programId].study_program},
        {property: "Department:", value: university.programs[programId].department},
        {property: "Degree:", value: university.programs[programId].degree},
        {property: "Degree title:", value: university.programs[programId].degree_title},
        {property: "Languages:", value: university.programs[programId].language.join(", ")},
        {property: "Duration:", value: university.programs[programId].duration},
        {property: "Cost:", value: university.programs[programId].cost + " €"},
        {property: "Practical lectures:", value: university.programs[programId].practical_lectures + " %"},
        {property: "Link:", value: university.programs[programId].link},
        {property: "Note:", value: university.programs[programId].note},
        {property: "Computer science:", value: university.programs[programId].percentage_of_subjects_on.computer_science + " %"},
        {property: "Cryptography:", value: university.programs[programId].percentage_of_subjects_on.cryptography + " %"},
        {property: "Humanistic:", value: university.programs[programId].percentage_of_subjects_on.humanistic + " %"},
        {property: "Mathematics:", value: university.programs[programId].percentage_of_subjects_on.mathematics + " %"},
        {property: "Privacy:", value: university.programs[programId].percentage_of_subjects_on.privacy + " %"},
        {property: "Security:", value: university.programs[programId].percentage_of_subjects_on.security + " %"}
      ]
      const element = <span>{university.programs[programId].study_program}</span>
      return  <ExcelFile filename={university.name + " - " + university.programs[programId].study_program} element={element}>
                <ExcelSheet data={dataSet} name="Study Program">
                  <ExcelColumn label="" value="property"/>
                  <ExcelColumn label="" value="value"/>
                </ExcelSheet>
              </ExcelFile>
    }

    return (
      <div className={!process && !isLoading ? 'admin_section scrollable' : 'admin_section'}>
        <div className="admin_header">
          <h3>Study programs overview</h3>
        </div>

        {process ?
          <div className="admin_process_message">
            {process.type === "delete" ?
              <>
                <Alert variant="warning">{"Are you sure you want to delete " + process.data.name + "?"}</Alert>
                <Button variant="danger" className="upload_button" onClick={()=> this.setState({process: null, newData: null, oldFileName: ""})}>Go back</Button>
                <Button variant="success" className="upload_button" onClick={()=> this.deleteUniversity(process.data)}>Yes, I am sure</Button>
              </>
              :
              <>
                <Alert variant={process.type}>{process.message}</Alert>
                <Button variant="outline-secondary" onClick={()=> this.setState({process: null, newData: null, oldFileName: ""})}>Go back</Button>
              </>
            }

          </div>
        : (

          isLoading ?
            <div className="admin_process_message"><Spinner animation="border" variant="primary" /><br/>Loading</div>
          : (

            newData ? (

              <div className="upload_form">

                <Form noValidate validated={validated} onSubmit={this.saveUniversity}>

                <div className="upload_program">
                  <h4>Created by</h4>

                    <Form.Group as={Row} controlId="username">
                      <Form.Label column sm={4}>Name</Form.Label>
                      <Col sm={8}>
                        <Form.Control disabled value={newData.created.username} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="usermail">
                      <Form.Label column sm={4}>E-mail</Form.Label>
                      <Col sm={8}>
                        <Form.Control disabled value={newData.created.usermail} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="userphone">
                      <Form.Label column sm={4}>Phone number</Form.Label>
                      <Col sm={8}>
                        <Form.Control disabled value={newData.created.userphone} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="created">
                      <Form.Label column sm={4}>Created</Form.Label>
                      <Col sm={8}>
                        <Form.Control disabled value={newData.created.timestamp} />
                      </Col>
                    </Form.Group>

                  </div>

                  <div className="upload_program">
                    <h4>University details</h4>

                      <Form.Group as={Row} controlId="name">
                        <Form.Label column sm={4}>University name *</Form.Label>
                        <Col sm={8}>
                          <Form.Control required value={newData.name} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.name = e.target.value
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid university name.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="country">
                        <Form.Label column sm={4}>Country *</Form.Label>
                        <Col sm={8}>
                          <Form.Control required value={newData.country} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.country = e.target.value
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid country.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={4}>GPS *</Form.Label>
                        <Col sm={4}>
                          <Form.Control required type="number" id="cor-lat" placeholder="Latitude" value={newData.cor.lat} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.cor.lat = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid coordinates.
                          </Form.Control.Feedback>
                        </Col>
                        <Col sm={4}>
                          <Form.Control required type="number" id="cor-lng" placeholder="Longitude" value={newData.cor.lng} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.cor.lng = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                            this.setState({newData: newItem})
                          }}/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid coordinates.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="world_university_rankings">
                        <Form.Label column sm={4}>World University Rankings</Form.Label>
                        <Col sm={8}>
                          <Form.Control value={newData.world_university_rankings} onChange={(e) => {
                            let newItem = JSON.parse(JSON.stringify(newData))
                            newItem.world_university_rankings = e.target.value
                            this.setState({newData: newItem})
                          }}/>
                        </Col>
                      </Form.Group>

                      </div>
                      {newData.programs.map((program, index) => {
                        const percent_checksum = Math.round((Object.keys(program.percentage_of_subjects_on).reduce((sum,key)=>sum+parseFloat(program.percentage_of_subjects_on[key]||0),0)) * 100) / 100
                        return (

                        <div className="upload_program" key={index}>
                          <Button variant="outline-danger" className="delete_program_button" size="sm" onClick={()=> this.deleteProgram(index)}
                          disabled={newData.programs.length === 1 ? true : false}>- Delete program</Button>
                          <h4>Study Program {index+1}</h4>

                          <Form.Group as={Row} controlId={"study_program-" + (index+1)}>
                            <Form.Label column sm={4}>Study program *</Form.Label>
                            <Col sm={8}>
                              <Form.Control required value={program.study_program} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].study_program = e.target.value
                                this.setState({newData: newItem})
                              }}/>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid name of the study program.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"department-" + (index+1)}>
                            <Form.Label column sm={4}>Department</Form.Label>
                            <Col sm={8}>
                              <Form.Control value={program.department} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].department = e.target.value
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"degree-" + (index+1)}>
                            <Form.Label column sm={4}>Degree *</Form.Label>
                            <Col sm={8}>
                              <Form.Control required  as="select" value={program.degree} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].degree = e.target.value
                                this.setState({newData: newItem})
                              }}>
                                <option></option>
                                <option>Bachelor</option>
                                <option>Master</option>
                                <option>Doctoral</option>
                                <option>Post-graduate</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid degree.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"degree_title-" + (index+1)}>
                            <Form.Label column sm={4}>Degree title</Form.Label>
                            <Col sm={8}>
                              <Form.Control value={program.degree_title} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].degree_title = e.target.value
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                          </Form.Group>

                          {newData.programs[index].language.map((item, indx) => {
                            return (
                              <Form.Group as={Row} key={indx}>
                                <Form.Label column sm={4} >{indx === 0 ? "Language *" : ""}</Form.Label>
                                <Col sm={5}>
                                  <Form.Control required id={"Language-"+(index+1)+"-"+(indx+1)} key={indx} value={item} onChange={(e) => {
                                    let newItem = JSON.parse(JSON.stringify(newData))
                                    newItem.programs[index].language[indx] = e.target.value
                                    this.setState({newData: newItem})
                                  }}/>
                                </Col>
                                <Col sm={3} className="language-row-button">
                                  <Button variant="outline-danger" size="sm" disabled={program.language.length === 1} onClick={() => this.deleteLang(index,indx)}>Delete language</Button>
                                </Col>
                              </Form.Group>
                            )
                          })}
                          <Form.Group as={Row}>
                            <Form.Label column sm={4} >{program.language.length === 0 ? "Language" : ""}</Form.Label>
                            <Col sm={5}>
                            </Col>
                            <Col sm={3} className="language-row-button">
                              <Button variant="outline-success" size="sm" onClick={() => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].language.push("")
                                this.setState({newData: newItem})
                              }}>Add language</Button>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"duration-" + (index+1)}>
                            <Form.Label column sm={4}>Duration *</Form.Label>
                            <Col sm={8}>
                              <Form.Control required as="select" value={program.duration} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].duration = e.target.value
                                this.setState({newData: newItem})
                              }}>
                                <option></option>
                                <option>1 year</option>
                                <option>2 years</option>
                                <option>3 years</option>
                                <option>4 years</option>
                                <option>other</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid duration.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"cost-" + (index+1)}>
                            <Form.Label column sm={4}>Cost per year</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" value={program.cost} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].cost = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>€</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"practical_lectures-" + (index+1)}>
                            <Form.Label column sm={4}>Practical lectures</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.practical_lectures} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].practical_lectures = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"link-" + (index+1)}>
                            <Form.Label column sm={4}>Link</Form.Label>
                            <Col sm={8}>
                              <Form.Control value={program.link} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].link = e.target.value
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"note-" + (index+1)}>
                            <Form.Label column sm={4}>Note</Form.Label>
                            <Col sm={8}>
                              <Form.Control value={program.note} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].note = e.target.value
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                          </Form.Group>

                          <h5>Percentage of subjects on</h5>

                          <Form.Group as={Row} controlId={"computer_science-" + (index+1)}>
                            <Form.Label column sm={4}>Computer Science</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.computer_science} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].percentage_of_subjects_on.computer_science = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"cryptography-" + (index+1)}>
                            <Form.Label column sm={4}>Cryptography</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.cryptography} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].percentage_of_subjects_on.cryptography = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"humanistic-" + (index+1)}>
                            <Form.Label column sm={4}>Humanistic</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.humanistic} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].percentage_of_subjects_on.humanistic = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"mathematics-" + (index+1)}>
                            <Form.Label column sm={4}>Mathematics</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.mathematics} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].percentage_of_subjects_on.mathematics = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"privacy-" + (index+1)}>
                            <Form.Label column sm={4}>Privacy</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.privacy} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].percentage_of_subjects_on.privacy = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"security-" + (index+1)}>
                            <Form.Label column sm={4}>Security</Form.Label>
                            <Col sm={4}>
                              <Form.Control type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.security} onChange={(e) => {
                                let newItem = JSON.parse(JSON.stringify(newData))
                                newItem.programs[index].percentage_of_subjects_on.security = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                this.setState({newData: newItem})
                              }}/>
                            </Col>
                            <Form.Label column sm={4}>%</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} controlId={"checksum-" + (index+1)}>
                            <Col sm={4}></Col>
                            <Col sm={8}><Form.Text className="text-muted">Percent checksum: <Badge variant={percent_checksum === 100 ? 'success':'danger'}>{percent_checksum} %</Badge></Form.Text></Col>
                          </Form.Group>

                        </div>
                      )
                    })}

                    <Button variant="outline-info" className="program_buttons" size="sm" onClick={this.addEmptyProgram}>+ Add next program</Button>

                    <div className="upload_buttons">
                      <Button variant="danger" className="upload_button" onClick={this.cancelUpload}>Cancel</Button>
                      <Button type="submit" variant="success" className="upload_button" >Confirm</Button>
                    </div>

                  </Form>
                </div>

            ) : (

              <div className="upload_form">
                <Table bordered >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>University</th>
                      <th>Study programs &nbsp;<span className="header-download-info">(Click to download)</span></th>
                      <th className="admin_overview_action_column">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    data.map((university, index) => {
                      return(
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>{university.name}</td>
                          <td><Table borderless size="sm"><tbody>{
                            university.programs.map((program, indx) =>{
                              return(
                                <tr key={indx}>
                                  <td className="admin_overview_badge">
                                    <Badge variant="secondary">{
                                      program.degree.includes("Master") ? 'Master' : (
                                        program.degree.includes("Bachelor") ? 'Bachelor' : (
                                          program.degree.includes("Doctoral") ? 'PhD' : (
                                            program.degree.includes("Post-graduate") ? 'Post-graduate' : program.degree
                                          )
                                        )
                                      )}</Badge>
                                  </td>
                                  <td className="link-export">{downloadProgram(university, indx)}</td>
                                </tr>
                              )
                            })
                          }</tbody></Table></td>
                          <td >
                            <Button variant="outline-primary" size="sm" block onClick={()=> this.editUniversity(university)}>Edit</Button>
                            <Button variant="outline-secondary" size="sm" block onClick={()=> this.setState({process:{type: "delete", data: university}})}>Delete</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
                {data.length === 0 ? <Alert variant="warning">No universities in the database.</Alert> : ""}
              </div>
            )
          )
        )}

      </div>
    )
  }
}

export default AdminOverview;
