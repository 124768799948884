import React, { useRef, useEffect} from "react"
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet'
import {Col} from 'react-bootstrap'
import L from 'leaflet'

function Map(props) {

  function getMarkers() {
    let markers = ""
    if (Array.isArray(props.markers) && props.markers.length){
      markers = props.markers.map((marker) => {

        let icon = null
        let zIndex = 0

        if(props.focus === marker.id){
          icon = require('../assets/marker-icon-red.png')
          zIndex = 1000
        } else{
          icon = require('../assets/marker-icon-blue.png')
        }

        const iconMarker = new L.Icon({
          iconUrl: icon,
          iconAnchor: [12, 41],
          popupAnchor:[0,-40]
        })

        const content = {
          id: marker.id,
          name: marker.name,
          country: marker.country,
          cor: marker.cor
        }

        return (
          <MyMarker key={marker.id} content={content} openPopup={props.focus === marker.id} iconMarker={iconMarker} zIndex={zIndex} onSelectMarker={handleClick}/>
        )

      })
    }
    return markers
  }

  function getBounds() {
    let bounds = []
    props.markers.map((marker) => bounds.push([marker.cor.lat, marker.cor.lng]))
    return bounds
  }

  // function getCenter() {
  //   if (props.focus !== null){
  //     return [
  //       props.markers[props.focus].cor.lat,
  //       props.markers[props.focus].cor.lng
  //     ]
  //   } else {
  //     return null
  //   }
  // }

  function handleClick(id) {
    props.onSelectMarker(id)
  }


  var mapProps = {
    maxZoom: 18,
    attributionControl: true,
    zoomControl: true,
    doubleClickZoom: true,
    scrollWheelZoom: true,
    dragging: true,
    animate: true,
    easeLinearity: 0.35
  }

  if (Array.isArray(props.markers) && props.markers.length > 1) {
    Object.assign(mapProps, {bounds: getBounds()})
  }
  if (props.markers.length === 1) {
    Object.assign(mapProps, {center: [props.markers[0].cor.lat, props.markers[0].cor.lng]})
  }

  return (
    <Col className="map-column">
      <LeafletMap {...mapProps} >
        <TileLayer url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'/>
        {getMarkers()}
      </LeafletMap>
    </Col>
  )

}


function MyMarker(props) {
  const markerRef = useRef(null)
  const { content, openPopup, iconMarker, zIndex } = props

  useEffect(() => {
    if (openPopup) markerRef.current.leafletElement.openPopup()
    if(markerRef.current.leafletElement.isPopupOpen() && !openPopup) markerRef.current.leafletElement.closePopup()
  })

  function handleClick(id) {
    props.onSelectMarker(id)
  }

  return (
    <Marker ref={markerRef} position={content.cor} icon={iconMarker} zIndexOffset={zIndex} onClick={() => handleClick(content.id)}>
      <Popup className="popup_body">
        <div className="popup_header">{content.name}</div>
        <div className="popup_content">{content.country}</div>
      </Popup>
    </Marker>
  )
}

export default Map
