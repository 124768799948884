import React from 'react'
import Axios from 'axios'
import {Alert, Badge, Button, Col, Form, Spinner, Row} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css"
import {Config} from '../config.js'


class AddRequest extends React.Component {
  constructor() {
    super()
    this.state = {
      newData: {
        created: {
          username: "",
          usermail: "",
          userphone: "",
          timestamp: ""
        },
        name: "",
        country : "",
        cor : {
          lat : "",
          lng : ""
        },
        world_university_rankings : "",
        programs: [this.emptyProgram()]
      },
      isLoading: false,
      process: null,
      validated: false
    }
  }

  emptyProgram = () => {
    let d = new Date()
    return ({
      study_program : "",
      department : "",
      degree : "",
      degree_title : "",
      language : [""],
      duration : "",
      cost : "",
      link : "",
      note: "",
      collected: (d.getFullYear()-1) + "-" + d.getFullYear(),
      practical_lectures: "",
      percentage_of_subjects_on: {
        computer_science: "",
        cryptography: "",
        humanistic: "",
        mathematics: "",
        privacy: "",
        security: ""
      }
    })
  }

  saveNewData = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({validated: true})

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    this.setState({isLoading: true, validated: false})

    let dataToSend = this.state.newData
    let date = new Date()
    dataToSend.created.timestamp = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    dataToSend.programs.forEach((program, index) => {
      dataToSend.programs[index].note = (program.collected) ? "Data collected in academic year " + program.collected : ""
      delete dataToSend.programs[index].collected
    })

    Axios({
      method: 'post',
      url: Config.server.addRequest,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: {
        'universities': JSON.stringify(dataToSend)
      }
    })
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.setState({isLoading: false, process: {type: "success", message: "Success! After we process your request, we'll send you a confirmation email."}})
        } else {
          let message = responseData.message.includes("exists") ? responseData.message : "Something went wrong. Please try it later."
          this.setState({isLoading: false, process: {type: "danger", message: message}})
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        this.setState({isLoading: false, process: {type: "danger", message: "Something went wrong. Please try it later."}})
        console.log(error)
      })

  }

  addEmptyProgram = () => {
    let newItem = JSON.parse(JSON.stringify(this.state.newData))
    newItem.programs.push(this.emptyProgram())
    this.setState({newData: newItem})
  }

  deleteProgram = (id) => {
    let newItem = JSON.parse(JSON.stringify(this.state.newData))
    newItem.programs.splice(id, 1)
    this.setState({newData: newItem})
  }

  deleteLang = (idP, idL) => {
    let tmp = JSON.parse(JSON.stringify(this.state.newData))
    tmp.programs[idP].language.splice(idL,1)
    this.setState({newData: tmp})
  }




  render(){
    const { isLoading, newData, process, validated } = this.state

    return (

        <div className="admin_main scrollable">

              <div className="admin_container">
              <div className='admin_section'>
                <div className="admin_header">
                  <h3>Add new university</h3>
                </div>

                {process ?
                  <div className="admin_process_message">
                    <Alert variant={process.type}>{process.message}</Alert>
                    {process.type === "success" ?
                      <Link to={Config.appRoot} ><Button variant="outline-secondary">Go to Main Page</Button></Link> :
                      <Button variant="outline-secondary" onClick={()=> this.setState({process: null})}>Go back</Button>
                    }
                  </div>
                  : (

                    isLoading ?
                      <div className="admin_process_message"><Spinner animation="border" variant="primary" /><br/>Uploading</div>
                      : (

                        <div className="upload_form">

                          <Form noValidate validated={validated} onSubmit={this.saveNewData}>

                          <div className="upload_program">
                            <h4>Your contact information</h4>

                              <Form.Group as={Row} controlId="username">
                                <Form.Label column sm={4}>Your name *</Form.Label>
                                <Col sm={8}>
                                  <Form.Control required value={newData.created.username} onChange={(e) => {
                                    let newItem = JSON.parse(JSON.stringify(newData))
                                    newItem.created.username = e.target.value
                                    this.setState({newData: newItem})
                                  }}/>
                                  <Form.Control.Feedback type="invalid">
                                    Please type your name.
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row} controlId="usermail">
                                <Form.Label column sm={4}>Your e-mail *</Form.Label>
                                <Col sm={8}>
                                  <Form.Control type="email" required value={newData.created.usermail} onChange={(e) => {
                                    let newItem = JSON.parse(JSON.stringify(newData))
                                    newItem.created.usermail = e.target.value
                                    this.setState({newData: newItem})
                                  }}/>
                                  <Form.Control.Feedback type="invalid">
                                    Please type your e-mail.
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row} controlId="userphone">
                                <Form.Label column sm={4}>Your phone number</Form.Label>
                                <Col sm={8}>
                                  <Form.Control value={newData.created.userphone} onChange={(e) => {
                                    let newItem = JSON.parse(JSON.stringify(newData))
                                    newItem.created.userphone = e.target.value
                                    this.setState({newData: newItem})
                                  }}/>
                                </Col>
                              </Form.Group>

                            </div>

                            <div className="upload_program">
                              <h4>University details</h4>

                                <Form.Group as={Row} controlId="name">
                                  <Form.Label column sm={4}>University name *</Form.Label>
                                  <Col sm={8}>
                                    <Form.Control required value={newData.name} onChange={(e) => {
                                      let newItem = JSON.parse(JSON.stringify(newData))
                                      newItem.name = e.target.value
                                      this.setState({newData: newItem})
                                    }}/>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a valid university name.
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="country">
                                  <Form.Label column sm={4}>Country *</Form.Label>
                                  <Col sm={8}>
                                    <Form.Control required value={newData.country} onChange={(e) => {
                                      let newItem = JSON.parse(JSON.stringify(newData))
                                      newItem.country = e.target.value
                                      this.setState({newData: newItem})
                                    }}/>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a valid country.
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                  <Form.Label column sm={4}>GPS *</Form.Label>
                                  <Col sm={4}>
                                    <Form.Control required type="number" id="cor-lat" placeholder="Latitude" value={newData.cor.lat} onChange={(e) => {
                                      let newItem = JSON.parse(JSON.stringify(newData))
                                      newItem.cor.lat = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                      this.setState({newData: newItem})
                                    }}/>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a valid coordinates.
                                    </Form.Control.Feedback>
                                  </Col>
                                  <Col sm={4}>
                                    <Form.Control required type="number" id="cor-lng" placeholder="Longitude" value={newData.cor.lng} onChange={(e) => {
                                      let newItem = JSON.parse(JSON.stringify(newData))
                                      newItem.cor.lng = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                      this.setState({newData: newItem})
                                    }}/>
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a valid coordinates.
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="world_university_rankings">
                                  <Form.Label column sm={4}>World University Rankings</Form.Label>
                                  <Col sm={8}>
                                    <Form.Control value={newData.world_university_rankings} onChange={(e) => {
                                      let newItem = JSON.parse(JSON.stringify(newData))
                                      newItem.world_university_rankings = e.target.value
                                      this.setState({newData: newItem})
                                    }}/>
                                  </Col>
                                </Form.Group>

                              </div>
                                {newData.programs.map((program, index) => {
                                  const percent_checksum = Math.round((Object.keys(program.percentage_of_subjects_on).reduce((sum,key)=>sum+parseFloat(program.percentage_of_subjects_on[key]||0),0)) * 100) / 100
                                  return (

                                  <div className="upload_program" key={index}>
                                    <Button variant="outline-danger" className="delete_program_button" size="sm" onClick={()=> this.deleteProgram(index)}
                                    disabled={newData.programs.length === 1 ? true : false}>- Delete program</Button>
                                    <h4>Study Program {index+1}</h4>

                                    <Form.Group as={Row} controlId={"study_program-" + (index+1)}>
                                      <Form.Label column sm={4}>Study program *</Form.Label>
                                      <Col sm={8}>
                                        <Form.Control required value={program.study_program} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].study_program = e.target.value
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid name of the study program.
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"department-" + (index+1)}>
                                      <Form.Label column sm={4}>Department</Form.Label>
                                      <Col sm={8}>
                                        <Form.Control value={program.department} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].department = e.target.value
                                          this.setState({newData: newItem})
                                        }}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"degree-" + (index+1)}>
                                      <Form.Label column sm={4}>Degree *</Form.Label>
                                      <Col sm={8}>
                                        <Form.Control required as="select" value={program.degree} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].degree = e.target.value
                                          this.setState({newData: newItem})
                                        }}>
                                          <option></option>
                                          <option>Bachelor</option>
                                          <option>Master</option>
                                          <option>Doctoral</option>
                                          <option>Post-graduate</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid degree.
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"degree_title-" + (index+1)}>
                                      <Form.Label column sm={4}>Degree title</Form.Label>
                                      <Col sm={8}>
                                        <Form.Control value={program.degree_title} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].degree_title = e.target.value
                                          this.setState({newData: newItem})
                                        }}/>
                                      </Col>
                                    </Form.Group>

                                    {newData.programs[index].language.map((item, indx) => {
                                      return (
                                        <Form.Group as={Row} key={indx}>
                                          <Form.Label column sm={4} >{indx === 0 ? "Language *" : ""}</Form.Label>
                                          <Col sm={5}>
                                            <Form.Control required id={"Language-"+(index+1)+"-"+(indx+1)} key={indx} value={item} onChange={(e) => {
                                              let newItem = JSON.parse(JSON.stringify(newData))
                                              newItem.programs[index].language[indx] = e.target.value
                                              this.setState({newData: newItem})
                                            }}/>
                                          </Col>
                                          <Col sm={3} className="language-row-button">
                                            <Button variant="outline-danger" size="sm" disabled={program.language.length === 1} onClick={() => this.deleteLang(index,indx)}>Delete language</Button>
                                          </Col>
                                        </Form.Group>
                                      )
                                    })}
                                    <Form.Group as={Row}>
                                      <Form.Label column sm={4} >{program.language.length === 0 ? "Language" : ""}</Form.Label>
                                      <Col sm={5}>
                                      </Col>
                                      <Col sm={3} className="language-row-button">
                                        <Button variant="outline-success" size="sm" onClick={() => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].language.push("")
                                          this.setState({newData: newItem})
                                        }}>Add language</Button>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"duration-" + (index+1)}>
                                      <Form.Label column sm={4}>Duration *</Form.Label>
                                      <Col sm={8}>
                                        <Form.Control required as="select" value={program.duration} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].duration = e.target.value
                                          this.setState({newData: newItem})
                                        }}>
                                          <option></option>
                                          <option>1 year</option>
                                          <option>2 years</option>
                                          <option>3 years</option>
                                          <option>4 years</option>
                                          <option>other</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid duration.
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"cost-" + (index+1)}>
                                      <Form.Label column sm={4}>Cost per year</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control type="number" value={program.cost} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].cost = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                      </Col>
                                      <Form.Label column sm={4}>€</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"practical_lectures-" + (index+1)}>
                                      <Form.Label column sm={4}>Practical lectures</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control type="number" min="0" max="100" step=".01" value={program.practical_lectures} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].practical_lectures = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"link-" + (index+1)}>
                                      <Form.Label column sm={4}>Link</Form.Label>
                                      <Col sm={8}>
                                        <Form.Control value={program.link} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].link = e.target.value
                                          this.setState({newData: newItem})
                                        }}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"collected-" + (index+1)}>
                                      <Form.Label column sm={4}>Data collected in academic year</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control value={program.collected} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].collected = e.target.value
                                          this.setState({newData: newItem})
                                        }}/>
                                      </Col>
                                    </Form.Group>

                                    <h5>Percentage of subjects on</h5>

                                    <Form.Group as={Row} controlId={"computer_science-" + (index+1)}>
                                      <Form.Label column sm={4}>Computer Science *</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.computer_science} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].percentage_of_subjects_on.computer_science = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid subject percentage.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"cryptography-" + (index+1)}>
                                      <Form.Label column sm={4}>Cryptography *</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.cryptography} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].percentage_of_subjects_on.cryptography = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid subject percentage.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"humanistic-" + (index+1)}>
                                      <Form.Label column sm={4}>Humanistic *</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.humanistic} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].percentage_of_subjects_on.humanistic = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid subject percentage.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"mathematics-" + (index+1)}>
                                      <Form.Label column sm={4}>Mathematics *</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.mathematics} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].percentage_of_subjects_on.mathematics = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid subject percentage.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"privacy-" + (index+1)}>
                                      <Form.Label column sm={4}>Privacy *</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.privacy} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].percentage_of_subjects_on.privacy = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid subject percentage.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"security-" + (index+1)}>
                                      <Form.Label column sm={4}>Security *</Form.Label>
                                      <Col sm={4}>
                                        <Form.Control required type="number" min="0" max="100" step=".01" value={program.percentage_of_subjects_on.security} onChange={(e) => {
                                          let newItem = JSON.parse(JSON.stringify(newData))
                                          newItem.programs[index].percentage_of_subjects_on.security = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)
                                          this.setState({newData: newItem})
                                        }}/>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid subject percentage.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Form.Label column sm={4}>%</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId={"checksum-" + (index+1)}>
                                      <Col sm={4}></Col>
                                      <Col sm={8}><Form.Text className="text-muted">Percent checksum: <Badge variant={percent_checksum === 100 ? 'success':'danger'}>{percent_checksum} %</Badge></Form.Text></Col>
                                    </Form.Group>

                                  </div>
                                )
                              })}

                              <Button variant="outline-info" className="program_buttons" size="sm" onClick={this.addEmptyProgram}>+ Add next program</Button>

                              <div className="upload_buttons">
                                {/*<Button variant="danger" className="upload_button" onClick={this.cancelUpload}>Cancel</Button>*/}
                                <Button type="submit" variant="success" className="upload_button" >Confirm</Button>
                              </div>

                            </Form>
                          </div>
                        )
                    )
                  }

                </div>
              </div>

        </div>
    )
  }
}

export default AddRequest;
