import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './assets/style.css';
import {Config} from './config.js';
import App from './components/App';
import Admin from './components/Admin';
import AddRequest from './components/AddRequest';
import NotFound from './components/NotFound';
import logo from '../src/assets/logo_2.png';
import logo_sparta from '../src/assets/logo_sparta.png';
import * as serviceWorker from './serviceWorker';

const routing = (
  <Router>
    <div className="App">
      <header className="App-header">
        <div className="logo-left">
          <Link to={Config.appRoot}>
            <img className="logo-left-img" src={logo} alt="logo-app" />
          </Link>
        </div>
        <h1>Cybersecurity Study Programs</h1>
        <div className="logo-right">
          <a href="https://www.sparta.eu/">
            <img className="logo-right-img" src={logo_sparta} alt="logo" />
          </a>
        </div>
      </header>
        <Switch>
          <Route component={App} exact path={Config.appRoot } />
          <Route component={Admin} path={Config.appRoot + "admin"} />
          <Route component={AddRequest} path={Config.appRoot + "add-university"} />
          <Route component={NotFound} />
        </Switch>
      <footer>
        <p>This project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement No 830892.</p>
      </footer>
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
