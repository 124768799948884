function LoadFilterOptions (input) {

  function pushToArray(arr, obj) {
    if(Array.isArray(obj)){
      obj.forEach((value) => {
        const index = arr.indexOf(value)
        if (index === -1) {arr.push(value)}
      })
    } else {
      const index = arr.indexOf(obj)
      if (index === -1) {arr.push(obj)}
    }
  }

  var output = {
    //"id": [],
		"country" : [],
		"degree" : [],
		"language" : [],
		"duration" : [],
		"cost" : []
		//"practical_lectures": []
    /*
		"percentage_of_subjects_on": {
			"computer_science": [],
			"cryptography": [],
			"humanistic": [],
			"mathematics": [],
			"privacy": [],
			"security": [],
		}
    */
  }

  input.forEach(university => {
    university.programs.forEach(program => {
      //pushToArray(output['id'], university.id)
      pushToArray(output['country'], university.country)
      pushToArray(output['degree'], program.degree)
      pushToArray(output['language'], program.language)
      pushToArray(output['duration'], program.duration)
      //pushToArray(output['cost'], program.cost)
      if (!isNaN(parseFloat(program.cost))) pushToArray(output['cost'], parseFloat(program.cost))
      //pushToArray(output['practical_lectures'], parseFloat(program.practical_lectures)) // nutno osetrit NaN
/*
      pushToArray(output['percentage_of_subjects_on']['computer_science'], parseFloat(university.percentage_of_subjects_on.computer_science))
      pushToArray(output['percentage_of_subjects_on']['cryptography'], parseFloat(university.percentage_of_subjects_on.cryptography))
      pushToArray(output['percentage_of_subjects_on']['humanistic'], parseFloat(university.percentage_of_subjects_on.humanistic))
      pushToArray(output['percentage_of_subjects_on']['mathematics'], parseFloat(university.percentage_of_subjects_on.mathematics))
      pushToArray(output['percentage_of_subjects_on']['privacy'], parseFloat(university.percentage_of_subjects_on.privacy))
      pushToArray(output['percentage_of_subjects_on']['security'], parseFloat(university.percentage_of_subjects_on.security))
*/
    })
  })

  return output
}

export default LoadFilterOptions
