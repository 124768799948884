import React from 'react'
import Axios from 'axios'
import Crypto from 'crypto'
import {Alert, Button, Form, Nav} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import {Config} from '../config.js'
import AdminImport from './AdminImport.js'
import AdminAdd from './AdminAdd.js'
import AdminOverview from './AdminOverview.js'
import AdminRequests from './AdminRequests.js'


class Admin extends React.Component {
  constructor() {
    super()
    this.state = {
      token: "",
      section: "nav-1"
    }
  }

  authorize = () => {
    Axios({
      method: 'post',
      url: Config.server.authorize,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: {
        'a': Crypto.createHash('sha256').update(Crypto.createHash('sha256').update(document.getElementById('formPassword').value).digest('hex')).digest('hex'),
        'b': Crypto.createHash('sha256').update(Crypto.createHash('sha256').update(document.getElementById('formLogin').value).digest('hex')).digest('hex')
      }
    })
      .then((response) => {
        this.setState({token: Crypto.createHash('sha256').update(document.getElementById('formPassword').value + Crypto.createHash('sha256').update(document.getElementById('formPassword').value + response.data.token).digest('hex')).digest('hex')})
      })
      .catch((error) => {
        console.log(error)
        this.setState({token: false})
      })
  }


  render(){

    let isLogged = (Crypto.createHash('sha256').update(this.state.token + Crypto.createHash('sha256').update("fr?chOc93n2t-$e_re7L").digest('hex')).digest('hex') === "38c518b6488491ed92a2550ed955a2b157170917197808222848b4d5db117136") ? true : false

    let sectionComponent =
      this.state.section === "nav-4" ? <AdminRequests/> : (
        this.state.section === "nav-2" ? <AdminAdd/> : (
          this.state.section === "nav-3" ? <AdminImport/> : <AdminOverview/>
        )
      )

    return (

        <div className="admin_main">
          {
            !isLogged? (
              <div>
                <div className="login">
                  <Form>
                    <Form.Group controlId="formLogin">
                      <Form.Control type="login" placeholder="Login" />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Button variant="primary" onClick={this.authorize}>
                      Enter
                    </Button>
                  </Form>
                </div>
                  {this.state.token === false ? <Alert variant="danger" className="login_error">Login failed!</Alert> : null}

              </div>

            ) : (

              <div className="admin_container">
                  <Nav id="admin_nav" className="justify-content-center" variant="pills" defaultActiveKey="nav-1">
                    <Nav.Item>
                      <Nav.Link eventKey="nav-1" onClick={()=>this.setState({section: "nav-1"})} >Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nav-2" onClick={()=>this.setState({section: "nav-2"})} >Add</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nav-3" onClick={()=>this.setState({section: "nav-3"})} >Import</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nav-4" onClick={()=>this.setState({section: "nav-4"})} >Requests</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  {sectionComponent}
              </div>

            )
          }

        </div>
    )
  }
}

export default Admin;
