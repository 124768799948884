import React from 'react'
import {Col, Collapse, Form} from 'react-bootstrap'
import LoadFilterOptions from '../utils/LoadFilterOptions.js'
import Slider from '@material-ui/core/Slider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'


class Filter extends React.Component {
  constructor(props) {
    super(props)
    let allOptions = LoadFilterOptions(props.data)
    this.state = {
      country: [],
      language: [],
      degree: [],
      duration: [],
      cost: Math.max(...allOptions.cost),
      practical_lectures: 0,
      computer_science: [0, 100],
      cryptography: [0, 100],
      humanistic: [0, 100],
      mathematics: [0, 100],
      privacy: [0, 100],
      security: [0, 100],
      allOptions: allOptions,
      collapseOptions: {
        country: false,
        language: false,
        degree: false,
        duration: false
      }
    }
  }

  getRelevantData = (filterData) => {
    /* vrací vyfiltrovaná data */
    let objsToShow = JSON.parse(JSON.stringify(this.props.data))


    if (Array.isArray(filterData.language) && filterData.language.length){
      objsToShow = objsToShow.filter((university) => {
        university.programs = university.programs.filter((program) => {
          //  Logický součin - universita musí mít všechny zvolené jazyky
          //this.state.language.every(r => university.language.includes(r)) &&
          return filterData.language.some(r => program.language.includes(r))
        })
        if (university.programs.length === 0) {return false} else {return true}
      })
    }

    if (Array.isArray(filterData.country) && filterData.country.length){
      objsToShow = objsToShow.filter((university) => {
        return filterData.country.some(r => r === university.country)
      })
    }

    if (Array.isArray(filterData.degree) && filterData.degree.length){
      objsToShow = objsToShow.filter((university) => {
        university.programs = university.programs.filter((program) => {
          return filterData.degree.some(r => r === program.degree)
        })
        if (university.programs.length === 0) {return false} else {return true}
      })
    }

    if (Array.isArray(filterData.duration) && filterData.duration.length){
      objsToShow = objsToShow.filter((university) => {
        university.programs = university.programs.filter((program) => {
          return filterData.duration.some(r => r === program.duration)
        })
        if (university.programs.length === 0) {return false} else {return true}
      })
    }

    objsToShow = objsToShow.filter((university) => {
      university.programs = university.programs.filter((program) => {
        let programValue = isNaN(parseFloat(program.cost)) ? 0 : parseFloat(program.cost)
        return programValue <= filterData.cost
      })
      if (university.programs.length === 0) {return false} else {return true}
    })

    objsToShow = objsToShow.filter((university) => {
      university.programs = university.programs.filter((program) => {
        let programValue = isNaN(parseFloat(program.practical_lectures)) ? 0 : parseFloat(program.practical_lectures)
        return programValue >= filterData.practical_lectures
      })
      if (university.programs.length === 0) {return false} else {return true}
    })

    objsToShow = objsToShow.filter((university) => {
      //university.programs = this.filteringRange(university.programs, filterData.practical_lectures, "practical_lectures")

      university.programs = this.filteringRange(university.programs, filterData.computer_science, "percentage_of_subjects_on.computer_science")
      university.programs = this.filteringRange(university.programs, filterData.cryptography, "percentage_of_subjects_on.cryptography")
      university.programs = this.filteringRange(university.programs, filterData.humanistic, "percentage_of_subjects_on.humanistic")
      university.programs = this.filteringRange(university.programs, filterData.mathematics, "percentage_of_subjects_on.mathematics")
      university.programs = this.filteringRange(university.programs, filterData.privacy, "percentage_of_subjects_on.privacy")
      university.programs = this.filteringRange(university.programs, filterData.security, "percentage_of_subjects_on.security")

      if (university.programs.length === 0) {return false} else {return true}
    })

    return objsToShow
  }

  filteringRange = (data, range, subject) => {
    if (range[0] !== 0 || range[1] !== 100){
      data = data.filter((item) => {
        let path = subject.split(".")
        if (path.length === 2){
          return parseFloat(item[path[0]][path[1]]) >= range[0] && parseFloat(item[path[0]][path[1]]) <= range[1]
        } else {
          return parseFloat(item[subject]) >= range[0] && parseFloat(item[subject]) <= range[1]
        }
      })
    }
    return data
  }

  updateArray = (arr, value) => {
    if (!arr.includes(value)){
      arr.push(value)
    } else {
      arr = arr.filter((item) => {return item !== value})
    }
    return arr
  }

  onFilterChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    switch (e.target.id.split("-", 1)[0]) {
      case 'country' : newState.country = this.updateArray(newState.country, e.target.value); break;
      case 'language' : newState.language = this.updateArray(newState.language, e.target.value); break;
      case 'degree' : newState.degree = this.updateArray(newState.degree, e.target.value); break;
      case 'duration' : newState.duration = this.updateArray(newState.duration, e.target.value); break;
      //case 'cost' : newState.cost = this.updateArray(newState.cost, e.target.value); break;
      case 'cost' : newState.cost = e.target.value; break;
      case 'practical_lectures' : newState.practical_lectures = e.target.value; break;
      case 'computer_science' : newState.computer_science = e.target.value; break;
      case 'cryptography' : newState.cryptography = e.target.value; break;
      case 'humanistic' : newState.humanistic = e.target.value; break;
      case 'mathematics' : newState.mathematics = e.target.value; break;
      case 'privacy' : newState.privacy = e.target.value; break;
      case 'security' : newState.security = e.target.value; break;
      default: break;
    }
    this.setState(newState)
    this.props.toShow(this.getRelevantData(newState))
  }

  printFilterOptions = (id, label, options, toShow, sort) => {

    let newState = JSON.parse(JSON.stringify(this.state))
    if (sort === "desc") { options.sort().reverse() }
    else { options = options.sort() }

    let additionalOpions =
      <div>
        <Collapse in={this.state.collapseOptions[id]}>
          <div id="collapse-text">
            {options.slice(toShow, options.length).map((value, index) => {
              return (<Form.Check label={value ? value : 'Undefined'} key={index+toShow} id={id + `-${index+toShow+1}`} value={value} />)
            })}
          </div>
        </Collapse>
        <div className="filterCheckCollapseButton" onClick={() => {
          newState.collapseOptions[id] = !newState.collapseOptions[id]
          this.setState(newState)
        }}>{this.state.collapseOptions[id] ? "Less" : "More" }{this.state.collapseOptions[id] ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
        </div>
      </div>

    return (
      <Form.Group controlId={id}>
        <Form.Label className="break"><h6>{label}</h6></Form.Label><br/>
        {options.slice(0, toShow).map((value, index) => {
            return (<Form.Check label={value ? value : 'Undefined'} key={index} id={id + `-${index+1}`} value={value} />)
        })}
        { options.length > toShow ? (additionalOpions) : ""
        }

      </Form.Group>
    )
  }

  printRangeOption = (id, label) => {
    return (
      <Form.Group className="numberFilter">
        <Form.Label className="smallLabel">{label}</Form.Label>
        <div className={"filterSlider"}>
          <Slider
            value={this.state[id]}
            onChange={(event,newValue) => this.onFilterChange({target:{value: newValue, id: id}})} valueLabelDisplay="auto" marks={[{value: 0, label: "0%"}, {value: 100, label: "100%"}]} aria-labelledby="range-slider" getAriaValueText={value => `${value}%`}
          />
        </div>
      </Form.Group>
    )
  }


  render(){

    const { allOptions} = this.state
    let maxCost = Math.max(...allOptions.cost)

    return (
      <Col xs={2} className="filter-column">
        <div className="content">

          <h5>Filter by:</h5>
          <Form className="filter_form" onChange={this.onFilterChange}>

            {this.printFilterOptions("language", "Language", allOptions.language, 5, "asc")}
            {this.printFilterOptions("country", "Country", allOptions.country, 5, "asc")}
            {this.printFilterOptions("degree", "Degree", allOptions.degree, 3, "asc")}
            {this.printFilterOptions("duration", "Duration", allOptions.duration, 5, "asc")}

            <Form.Group className="numberFilter">
              <Form.Label className="break"><h6>Cost per year max.</h6></Form.Label>
              <div className={"filterSlider"}>
                <Slider
                  value={this.state["cost"]} valueLabelDisplay="auto" getAriaValueText={value => `${value}€`} aria-labelledby="slider"
                  onChange={(event,newValue) => this.onFilterChange({target:{value: newValue, id: "cost"}})}
                  marks={[{value: 0, label: "0 €"}, {value: maxCost, label: maxCost+" €"}]}
                  max={maxCost} min={0} step={100} id="slider-cost"
                />
              </div>
            </Form.Group>

            <Form.Group className="numberFilter">
              <Form.Label className="break"><h6>Practical lectures min.</h6></Form.Label>
              <div className={"filterSlider"}>
                <Slider
                  value={this.state["practical_lectures"]} valueLabelDisplay="auto" getAriaValueText={value => `${value}%`} aria-labelledby="track-inverted-slider" track="inverted"
                  onChange={(event,newValue) => this.onFilterChange({target:{value: newValue, id: "practical_lectures"}})}
                  marks={[{value: 0, label: "0%"}, {value: 100, label: "100%"}]}
                />
              </div>
            </Form.Group>

            <Form.Label className="bottomSpace"><h6>Compulsory subjects with main focus on</h6></Form.Label>

            {this.printRangeOption("computer_science", "Computer Science")}
            {this.printRangeOption("cryptography", "Cryptography")}
            {this.printRangeOption("humanistic", "Humanistic")}
            {this.printRangeOption("mathematics", "Mathematics")}
            {this.printRangeOption("privacy", "Privacy")}
            {this.printRangeOption("security", "Security")}

          </Form>
        </div>
      </Col>
    )
  }

}

export default Filter
