export const Config = {
	appRoot      :	'/',

	server       :   {
		domain        		: 'https://study-programs.informacni-bezpecnost.cz',
		getData       		: 'https://study-programs.informacni-bezpecnost.cz/data/getData.php',
		saveData    			: 'https://study-programs.informacni-bezpecnost.cz/data/saveData.php',
		editData    			: 'https://study-programs.informacni-bezpecnost.cz/data/editData.php',
		deleteData    		: 'https://study-programs.informacni-bezpecnost.cz/data/deleteData.php',
		authorize					: 'https://study-programs.informacni-bezpecnost.cz/data/authorize.php',
		addRequest				:	'https://study-programs.informacni-bezpecnost.cz/data/addRequest.php'
	}

	// server       :   {
	// 	domain        	: 'localhost',
	// 	getData       	: 'localhost/universities_server/data/getData.php',
	// 	saveData   			: 'localhost/universities_server/data/saveData.php',
	// 	editData   			: 'localhost/universities_server/data/editData.php',
	// 	deleteData			: 'localhost/universities_server/data/deleteData.php',
	// 	authorize				: 'localhost/universities_server/data/authorize.php',
// 		addRequest			:	'localhost/universities_server/data/addRequest.php'
	// }
}
